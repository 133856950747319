/* eslint-disable @typescript-eslint/no-use-before-define */
import { hasCookie, setCookie } from 'cookies-next';
import Link from 'next/link';
import { useEffect, useState } from 'react';

const CookieBanner = () => {
  const [consent, setConsent] = useState(true);
  useEffect(() => {
    setConsent(hasCookie('analytics'));
  }, []);

  const [showSettings, setShowSettings] = useState(false);
  const [showCookieConsent, setShowCookieConsent] = useState(true);
  const [essentialCookiesEnabled, setEssentialCookiesEnabled] = useState(true);
  const [marketingCookiesEnabled, setMarketingCookiesEnabled] = useState(false);
  const [analyticsCookiesEnabled, setAnalyticsCookiesEnabled] = useState(false);
  const [consulteerCookieEnabled, setConsulteerCookieEnabled] = useState(false);
  const [showConsent, setShowConsent] = useState(true);

  useEffect(() => {
    setShowConsent(hasCookie('localConsent'));
  }, []);

  const acceptCookie = () => {
    setShowConsent(true);
    setCookie('localConsent', 'true', {});
  };

  if (showConsent) {
    return null;
  }

  const handleSettingsClick = () => {
    setShowSettings(true);
  };

  const handleSaveClick = () => {
    setShowSettings(false);
    setAllCookies(
      essentialCookiesEnabled,
      marketingCookiesEnabled,
      analyticsCookiesEnabled,
      consulteerCookieEnabled
    );
    setShowCookieConsent(false);
    setConsent(true);
    setCookie('analytics', analyticsCookiesEnabled);
    setCookie('marketing', marketingCookiesEnabled);
    setCookie('consulteerCookieEnabled', 'true', {
      maxAge: 60 * 60 * 24 * 365
    });
    if (analyticsCookiesEnabled) {
      window.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted'
      });
    }
  };

  const handleDisableAllClick = () => {
    setAllCookiesState(true, false, false, true);
    setAllCookies(true, false, false, true);
    setShowSettings(false);
    setConsent(false);
    setCookie('analytics', 'false');
    setCookie('marketing', 'false');
    setShowCookieConsent(false);
  };

  const handleAllowAllClick = () => {
    setAllCookies(true, true, true, true);
    setShowSettings(false);
    setConsent(true);
    setCookie('analytics', 'true', { maxAge: 60 * 60 * 24 * 365 });
    setCookie('marketing', 'true', { maxAge: 60 * 60 * 24 * 365 });
    setCookie('consulteer_cookie_consent', 'true', { path: '/' });
    window.gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted'
    });
    setShowCookieConsent(false);
  };

  const setAllCookies = (
    essential: boolean,
    marketing: boolean,
    analytics: boolean,
    consulteer_cookie_consent: boolean
  ) => {
    setCookie('essential', essential, { path: '/' });
    setCookie('marketing', marketing, { path: '/' });
    setCookie('analytics', analytics, { path: '/' });
    setCookie('consulteer_cookie_consent', consulteer_cookie_consent, {
      path: '/'
    });
    setCookie('analytics', 'true', { maxAge: 60 * 60 * 24 * 365 });
    setCookie('marketing', 'true', { maxAge: 60 * 60 * 24 * 365 });
    setConsent(true);
  };

  const setAllCookiesState = (
    essential: boolean,
    marketing: boolean,
    analytics: boolean,
    consulteer_cookie_consent: boolean
  ) => {
    setEssentialCookiesEnabled(essential);
    setMarketingCookiesEnabled(marketing);
    setAnalyticsCookiesEnabled(analytics);
    setConsulteerCookieEnabled(consulteer_cookie_consent);
    setConsent(true);
  };

  const handleSliderChange = (label: any) => {
    switch (label) {
      case 'marketing':
        setMarketingCookiesEnabled(!marketingCookiesEnabled);
        break;
      case 'analytics':
        setAnalyticsCookiesEnabled(!analyticsCookiesEnabled);
        break;
      default:
        break;
    }
  };

  return (
    <div className="w-full h-full bg-brand-deep-blue bg-opacity-[0.35] fixed z-[999]">
      {showSettings ? (
        <div className="fixed z-[999] top-0 bottom-0 left-0 right-0 h-screen w-screen flex justify-center items-center text-left">
          <div
            className="bg-[#0a0a33] fixed top-1/2 left-1/2 transform-translate-[-50%] -translate-y-1/2 -translate-x-1/2 z-900
            lg:px-16 lg:pt-8 lg:pb-16  p-8 lg:w-[40%] sm:w-[90%] w-[90%] max-h-screen sm:overflow-auto"
          >
            <button
              type="button"
              className="absolute right-[3vmin] top-[3vmin] cursor-pointer"
              onClick={() => setShowSettings(false)}
              aria-label="Close Settings"
            >
              <span className="absolute top-0 right-0 text-[50px] text-white leading-[50px] text-center text-green-400">
                &times;
              </span>
            </button>

            <h3 className="lg:text-3xl text-2xl font-bold leading-[1.38] text-white lg:mt-12 md:mt-12 mt-4">
              Advanced Cookie Settings
            </h3>
            <div className="flex items-baseline justify-between mt-[32px]">
              <h4 className="text-white text-base font-bold leading-11">
                Essential Cookies
              </h4>
              <label
                className="relative inline-block w-12 h-6"
                htmlFor="essential-cookies"
              >
                <input
                  type="checkbox"
                  id="essential-cookies"
                  className="hidden"
                  checked={essentialCookiesEnabled}
                  readOnly
                />
                <span
                  className={`absolute w-12 cursor-pointer h-6 bg-gray-400 rounded-full transition-transform duration-300 ease-in-out 
                transform left-1 top-1 bg-green-500
                } `}
                />
                <span
                  className={`absolute w-7 h-7 cursor-pointer bg-white rounded-full transition-transform duration-300 ease-in-out 
                 translate-x-4 left-4 top-0.5`}
                />
              </label>
            </div>
            <p className="text-white text-base font-normal leading-5 tracking-normal max-w-[600px] mt-6 lg:text-[17px] text-sm">
              These cookies enable core functionality such as security,
              verification of identity and network management. These cookies
              can&apos;t be disabled.
            </p>
            <div className="flex items-baseline justify-between lg:mt-12 mt-8">
              <h4 className="text-white text-base font-bold leading-11">
                Marketing Cookies
              </h4>
              <label
                className="relative inline-block w-12 h-6"
                htmlFor="marketing-cookies"
              >
                <input
                  type="checkbox"
                  id="marketing-cookies"
                  className="hidden"
                  checked={marketingCookiesEnabled}
                  onChange={() => handleSliderChange('marketing')}
                />
                <span
                  className={`absolute w-12 cursor-pointer h-6 bg-gray-400 rounded-full transition-transform duration-300 ease-in-out 
                transform left-1 top-1 ${
                  marketingCookiesEnabled ? 'bg-green-500' : 'bg-[#ccc]'
                } `}
                />
                <span
                  className={`absolute w-7 h-7 cursor-pointer bg-white rounded-full transition-transform duration-300 ease-in-out 
                  ${
                    marketingCookiesEnabled
                      ? 'translate-x-4 '
                      : 'translate-x-0 left-[0px]'
                  } left-4 top-0.5`}
                />
              </label>
            </div>
            <p className="text-white text-base font-normal leading-5 tracking-normal max-w-[600px] mt-6 lg:text-[17px] text-sm">
              These cookies are used to track advertising effectiveness to
              provide a more relevant service.
            </p>
            <div className="flex items-baseline justify-between lg:mt-12 mt-6">
              <h4 className="text-white text-base font-bold leading-11">
                Analytics Cookies
              </h4>
              <label
                className="relative inline-block w-12 h-6"
                htmlFor="analytics-cookies"
              >
                <input
                  type="checkbox"
                  id="analytics-cookies"
                  className="hidden"
                  checked={analyticsCookiesEnabled}
                  onChange={() => handleSliderChange('analytics')}
                />
                <span
                  className={`absolute w-12 cursor-pointer h-6 bg-gray-400 rounded-full transition-transform duration-300 ease-in-out 
                transform left-1 top-1 ${
                  analyticsCookiesEnabled ? 'bg-green-500' : 'bg-[#ccc]'
                } `}
                />
                <span
                  className={`absolute w-7 h-7 cursor-pointer bg-white rounded-full transition-transform duration-300 ease-in-out 
                  ${
                    analyticsCookiesEnabled
                      ? 'translate-x-4 '
                      : 'translate-x-0 left-[0px]'
                  } left-4 top-0.5`}
                />
              </label>
            </div>
            <p className="text-white text-base font-normal leading-5 tracking-normal max-w-[600px] mt-6 lg:text-[17px] text-sm">
              These cookies help us understand how visitors interact with our
              website.
            </p>
            <div className="lg:mt-20 md:mt-20 sm:mt-10 mt-10 flex items-center justify-between">
              <div>
                <button
                  type="button"
                  className="mr-5 text-white text-base font-medium leading-normal tracking-normal uppercase border-none focus:outline-none"
                  onClick={() => {
                    handleSaveClick();
                    acceptCookie();
                  }}
                >
                  Save Settings
                </button>
                <button
                  type="button"
                  className="mr-5 text-white text-base font-medium leading-normal tracking-normal uppercase border-none focus:outline-none"
                  onClick={() => handleDisableAllClick()}
                >
                  Disable All
                </button>
              </div>
              <button
                type="button"
                className="text-green-500 text-lg font-bold leading-5 tracking-wide uppercase"
                onClick={() => {
                  handleAllowAllClick();
                  acceptCookie();
                }}
              >
                Allow All
              </button>
            </div>
          </div>
        </div>
      ) : (
        showCookieConsent && (
          <div className="fixed top-[50%] left-[50%] translate-y-[-50%] translate-x-[-45%]">
            <div
              className="transform h-full 
              translate-x-[-99%] xl:translate-x-[-99%] skew-y-[329deg] top-[10px] lg:top-[22px] 
              absolute bg-brand-deep-blue border-brand-green border-[8px] border-r-[0] w-[35px] lg:w-[72px]"
            />
            <div className="bg-brand-deep-blue min-w-[220px] border-brand-green border-[8px] border-l-[0] z-[1000] flex-start px-3 pr-12 xl:px-12 py-12">
              <div className="mx-auto lg:flex lg:flex-col lg:justify-center xl:flex-row xl:gap-4 xl:content-end">
                <div className="lg:flex lg:justify-center">
                  <p className="text-white">
                    This site uses cookies to ensure you get the best experience
                    on our website.&#160;
                    <Link
                      className="text-brand-green underline hover:text-white block"
                      href="/privacy"
                    >
                      Find out more
                    </Link>
                  </p>
                </div>
                <div className="flex basis-[85%] mt-8 flex-col gap-8 justify-between items-start md:items-center lg:justify-center xl:mt-0 xl:flex-row-reverse lg:items-center">
                  <button
                    type="button"
                    onClick={() => {
                      handleAllowAllClick();
                      acceptCookie();
                    }}
                    className="green-button px-3 py-3 xl:px-6 ml-3 md:ml-0 xl:ml-0"
                  >
                    Accept all
                  </button>
                  <button
                    type="button"
                    className="text-brand-green font-bold uppercase text-left xl:text-center"
                    onClick={handleSettingsClick}
                  >
                    Customize Settings
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default CookieBanner;
